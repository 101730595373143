@import "~scss/breakpoints";
@import "~scss/colors";

#root {
  padding-top: var(--navHeight);
}

body {
  font-family: var(--sans);
  // color: var(--black);
  position: unset !important;
}

// footer too?
nav.nav + section {
  min-height: calc(100vh - var(--navHeight) - var(--footerHeight));
}

.grid {
  display: grid;
  list-style: none;
  margin: 0 auto;
  max-width: var(--max);
}

.grid--25 {
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include breakpoint(md) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.grid--33 {
  grid-template-columns: 1f;
  grid-gap: 1rem;

  &.mobile-50 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  @include breakpoint(md) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;

    &.mobile-50 {
      grid-gap: 40px;
    }
  }

  @include breakpoint(lg) {
    grid-template-columns: 1fr 1fr 1fr;

    &.mobile-50 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.grid--50 {
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 40px;
  }
}

// .article-grid .grid--50-sm,
// .grid--50-sm {
//   grid-template-columns: 100%;

//   .news-module {
//     // grid-column-start: 1;
//     // grid-column-end: 2;

//     .body-text {
//       display: none;
//     }
//   }

//   // @include breakpoint(lg) {
//   //   .news-module {
//   //     grid-column-start: 1;
//   //     grid-column-end: 2;
//   //   }
//   // }

//   @include breakpoint(xl) {
//     grid-template-columns: 1fr 1fr;

//     .body-text {
//       display: block;
//     }
//   }
// }

.page-grid {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;

  @include breakpoint(md) {
    display: grid;
    padding: 1rem var(--pad-lg);
    margin: 0 auto;
    max-width: var(--bigmax);
  }

  @include breakpoint(lg) {
    padding: 1rem var(--pad-lg) 2rem;
  }
}

.grid--left-col {
  grid-template-columns: 1fr 2fr;
  grid-gap: 2rem;

  @include breakpoint(md) {
    grid-gap: 3rem;
  }

  @include breakpoint(xl) {
    grid-gap: 5rem;
  }
}

.grid--right-col {
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;

  @include breakpoint(md) {
    grid-gap: 3rem;
  }

  @include breakpoint(xl) {
    grid-gap: 5rem;
  }
}

.article-grid {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    margin: 0 auto;
    max-width: var(--bigmax);

    .grid--50 {
      grid-gap: 2rem;
    }

    .grid--50-sm {
      grid-template-columns: 1fr;
    }
  }

  @include breakpoint(lg) {
    padding: 1rem 2rem 2rem;

    .grid--50-sm {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.flex-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  .section-header {
    width: 100%;
  }

  &.list-length--3 li { margin-bottom: 1rem; }

  @include breakpoint(md) {
    &.list-length--3 {
      justify-content: space-around;

      li { width: calc(50% - 1rem); }
    }
  }

  @include breakpoint(lg) {
    &.list-length--3 li { width: calc(33% - 1rem); }
  }
}

.article-layout.page-grid .flex-list.link-module.max-width {
  padding: 0;
}

.social-list {
  justify-content: center;
  @include breakpoint(md) {
    justify-content: flex-start;
  }
}

div.center {
  text-align: center;
}

.max-width {
  margin: 0 auto;
  max-width: var(--bigmax);
  padding-left: var(--margin);
  padding-right: var(--margin);
}

.max-width-lg {
  margin: 0 auto;
  max-width: var(--bigmax);
}

.pad {
  padding: 1rem;

  @include breakpoint(md) {
    padding: 1.5rem;
  }
  @include breakpoint(lg) {
    padding: 2rem;
  }
}

.pad-lg {
  padding: var(--pad-lg);
}

.pad-top {
  padding-top: var(--pad-lg);
}

.pad-bottom {
  padding-bottom: var(--pad-lg);
}

.mobile-pad {
  padding-left: 2rem;
  padding-right: 2rem;
  @include breakpoint(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.border-top {
  border-top: 1px solid var(--bg-gray);
}

.border-bottom {
  border-bottom: 1px solid var(--bg-gray);
}

.full-height {
  min-height: calc(100vh - var(--navHeight) - var(--footerHeight));
}

a .framed-obj {
  &:before {
    @extend %hoverstate;
    z-index: 1;
  }

  &:hover {
    &:before {
      height: 100%;
    }
  }
}

.framed-obj {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:after {
    bottom: 0;
    content: '';
    left: 0rem;
    position: absolute;
    right: 0;
    top: 3rem;
    z-index: 0;
  }

  &.blue:after {
    background-color: var(--med-blue);
  }
  &.yellow:after {
    background-color: var(--yellow);
  }
  &.green:after {
    background-color: var(--green);
  }
  &.red:after {
    background-color: var(--light-red);
  }
  &.gray:after {
    @extend %bg--gray;
  }
}

.framed-obj {
  display: block;
  padding: 0 1.5rem 1.5rem 0;
}

.thumb .frame {
  height: 100px;
  width: 100px;

  .square {
    height: 100px;
  }

  .framed-obj {
    padding: 0 0.5rem 0.5rem 0;

    &:after {
      top: 1rem;
    }
  }

  .image-centered  {
    // padding-bottom: 100%;
  }

  img {
    // height: 100%;
    // max-width: 100%;
    // width: auto;
  }
}

.image-abs {
  // overflow: hidden;
  position: relative;

  img {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    object-fit: cover;
  }
}

.image-centered {
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
  }
}

.sticky {
  overflow: auto;
  position: sticky;
  top: calc(1rem + var(--navHeight));
  z-index: 2;

  .button-actions {
    display: none;
  }

  @include breakpoint(md) {
    .button-actions {
      display: block;
    }

    img + .section-header {
      padding-top: 2rem;
    }
  }

  @include breakpoint(xxl) {
    max-height: 100vh;
  }
}

.sidebar.sticky {
  position: relative;
  order: 3;

  .button-actions {
    margin: 2rem 0 2rem;
    padding-bottom: 2rem;
    position: relative;

    &:after {
      background-color: var(--bg-gray);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 3rem;
    }

    a {
      margin: 5px 0;
    }
  }

  @include breakpoint(md) {
    position: sticky;
    order: unset;
  }
}

body {
  color: var(--black);
}

h1,h2,h3,h4,h5,p {
  font-style: normal;
}

.publish-date {
  @extend %type--date-time;
  line-height: var(--line-body);
}

.publish-date-heavy {
  color: var(--black);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.body-text {
  @extend %type--body-copy;
  margin: 1rem 0;

  p { margin: 1rem 0; }
}

.article .body-text {
  margin: 50px 0;
}

.article.donateBlock {
  padding-bottom: var(--pad-lg);
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  h3, p {
    text-align: center;
  }

  h3 {
    margin: 1rem 0;
  }
}

.plain-page {
  margin: 0 auto;
  max-width: 700px;
  padding: var(--pad);

  .page-header h2 {
    font-weight: 400;
    padding-top: 3rem;
    text-align: left;
  }
}

.reorder {
  display: flex;
  flex-direction: column;

  .resort-end {
    order: 10;
  }
}

iframe {
  max-height: 100%;
  max-width: 100%;
}

.loading-svg {
  min-height: calc(100vh - #{var(--navHeight)});
  position: relative;
  text-align: center;

  svg {
    animation: fadeIn 3s ease-in infinite;
    height: auto;
    left: 50%;
    margin: 0 auto;
    max-width: 100px;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transform-origin: center;
    width: 100%;
  }

  @include breakpoint(md) {
    svg {
      max-width: 200px;
    }
  }
}

@keyframes fadeIn {
  0%   { opacity: 0.1; filter: grayscale(1); }
  50%  { opacity: 1;   filter: grayscale(0); }
  100% { opacity: 0.1; filter: grayscale(1); }
}

@keyframes growInOut {
  0%   { transform: translate(-50%,-50%) scale(1); }
  5%   { transform: translate(-50%,-50%) scale(1.1); }
  10%  { transform: translate(-50%,-50%) scale(1); }
  30%  { transform: translate(-50%,-50%) scale(1.2); }
  50%  { transform: translate(-50%,-50%) scale(1.5); }
  80%  { transform: translate(-50%,-50%) scale(1.2); }
  90%  { transform: translate(-50%,-50%) scale(1.3); }
  100% { transform: translate(-50%,-50%) scale(1); }
}

.skiptranslate {
  // height: 0 !important;
  // overflow: hidden !important;
  // visibility: hidden !important;
  display: none !important;
}

#google_translate_element {
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

*::selection {
  background-color: var(--navy);
  color: var(--white);
}

// IE overrides
.is-ie .grid,
.is-ie .page-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.grid--right-col,
  &.grid--left-col {
    justify-content: space-between;

    @include breakpoint(md) {
      > .sidebar,
      > aside {
        width: calc(33% - 3rem);
      }

      > .article,
      > .sidebar-stretch,
      > article {
        width: calc(66% - 3rem);
      }
    }

    @include breakpoint(xl) {
      > .sidebar,
      > aside {
        width: calc(33% - 5rem);
      }

      > .article,
      > .sidebar-stretch,
      > article {
        width: calc(66% - 5rem);
      }
    }
  }

  &.grid--50,
  &.grid--33,
  &.grid--25 {
    > li,
    > .card,
    > .grid-block {
      margin: 1rem 0;
      width: 100%;
    }
  }

  &.grid--33.mobile-50 {
    flex-direction: row;
    justify-content: space-between;

    > li,
    > .card {
      width: calc(50% - 0.5rem);
    }
  }

  @include breakpoint(md) {
    flex-direction: row;

    &.grid--50,
    &.grid--33,
    &.grid--25 {
      justify-content: space-between;

      > li,
      > .card,
      > .grid-block,
      &.mobile-50 > li {
        margin: 20px 0;
        width: calc(50% - 20px);
      }

      .full {
        width: calc(66% - 20px) !important;
      }
    }

    &.grid--50 .news-module-double {
      width: 100% !important;
    }

    &.grid--33 .news-module-double {
      width: calc(66% - 20px) !important;
    }

    &.filters {
      display: flex;

      .grid {
        display: flex;
      }

      > .grid-block {
        width: 50%;
      }

      .grid--50 > li,
      .grid--33 > li {
        margin: 0.5rem 0;
        width: calc(50% - 0.25rem);
      }
    }
  }

  @include breakpoint(lg) {
    &.grid--25 {
      > li,
      > .card {
        width: calc(25% - 20px);
      }
    }

    &.grid--33 {
      > li,
      > .card,
      &.mobile-50 > li {
        width: calc(33% - 20px);
      }
    }
  }
}
